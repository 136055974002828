<template>
  <div>
    <ui-component-modal
      :modalTitle="$t('Components.Vouchers.ModalEditBasicData.Main_Title')"
      :onClickCancel="onClickCancel"
      :showModal="showModal"
      :onClickSave="saveVoucher"
      :isSaving="isSaving"
      :isSavingSuccess="isSavingSuccess"
      :isSavingError="isSavingError"
      :hideFooterButtons="isSavingError || isSavingSuccess"
      :disableSaveButton="codeCheckCounter > 0 && !isCodeUnique"
      @closeModal="onClickCancel"
    >
      <template v-slot:content>
        <table
          v-if="mVoucher"
          class="table is-fullwidth is-striped"
        >
          <tbody>
            <tr>
              <td>
                {{ $t('Components.Vouchers.ModalEditBasicData.Label_Name') }}
              </td>
              <td>
                <input
                  class="input"
                  type="text"
                  v-model="mVoucher.Name"
                />
              </td>
            </tr>
            <tr v-if="voucher.Type !== 'Rate' && voucher.Type !== 'Package'">
              <td>
                {{ $t('Components.Vouchers.ModalEditBasicData.Label_Code') }}
              </td>
              <td>
                <input
                  @change="checkVoucherCodeUnique"
                  class="input"
                  :class="{
                    'is-danger': codeCheckCounter > 0 && !isCodeUnique,
                  }"
                  type="text"
                  v-model="mVoucher.Code"
                />
                <p v-if="codeCheckCounter > 0 && !isCodeUnique">
                  <span
                    class="help"
                    v-bind:class="{
                      'is-danger': codeCheckCounter > 0 && !isCodeUnique,
                    }"
                  >Code is not unique!</span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                {{
                  $t('Components.Vouchers.ModalEditBasicData.Label_Description')
                }}
              </td>
              <td>
                <textarea
                  class="textarea"
                  v-model="mVoucher.Description"
                  maxlength="6000"
                ></textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </ui-component-modal>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import voucherProvider from '@/providers/voucher'

export default {
  props: {
    voucher: {
      type: Object,
      default: null,
    },

    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      codeCheckCounter: 0,
      isCodeUnique: false,
      isSaving: false,
      isSavingError: false,
      isSavingSuccess: false,
      mVoucher: JSON.parse(JSON.stringify(this.voucher)),
    }
  },

  methods: {
    ...mapMutations('voucherStore', ['setVoucher']),

    checkVoucherCodeUnique() {
      if (
        this.voucher.Code !== this.mVoucher.Code &&
        this.mVoucher.Code.length > 0
      ) {
        this.isCodeUnique = false

        voucherProvider.methods
          .checkVoucherCodeUnique(this.mVoucher.Code, this.mVoucher.LocationId)
          .then((response) => {
            if (response.status === 200) {
              this.codeCheckCounter++
              this.isCodeUnique = response.data
            }
          })
      }
    },

    saveVoucher() {
      this.isSaving = true
      voucherProvider.methods
        .saveVoucher(this.mVoucher)
        .then((response) => {
          if (response.status === 200) {
            this.setVoucher(response.data)
            this.isSavingSuccess = true

            setTimeout(() => {
              this.$emit('voucherEdited', response.data)
              this.onClickCancel()
            }, 1500)
          }
        })
        .catch((error) => {
          //console.log(error.response)
          this.isSavingError = true
        })
        .finally(() => {
          this.isSaving = false
        })
    },
  },
}
</script>
